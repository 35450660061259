// ** React Imports
import { Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import { UserContext } from './utility/context/User'

// ** Spinner (Splash Screen)
// import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
// import "./@core/components/ripple-button"

// ** PrismJS
// import "prismjs"
// import "prismjs/themes/prism-tomorrow.css"
// import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
// import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
// import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
// import "./@core/assets/fonts/feather/iconfont.css"
// import "./@core/scss/core.scss"

import "./assets/scss/style.scss"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "./assets/scss/custom.scss"
import './index.scss'

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import './configs/i18n'
import './configs/pusher'

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense>
        <UserContext>
          <AbilityContext.Provider value={ability}>
            <LazyApp />
          </AbilityContext.Provider>
        </UserContext>
      </Suspense>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
