// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    // Enables the i18next backend
    .use(Backend)

    // Enable automatic language detection
    .use(LanguageDetector)

    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('i18nextLng') ?? 'pl',
        backend: {
            /* translation file path */
            loadPath: `${process.env.REACT_APP_API_URL}/api/lang/front/{{lng}}.json`,
            crossDomain: true,
            withCredentials: false,
            overrideMimeType: false,
            requestOptions: {
                mode: 'cors',
                // credentials: 'same-origin',
                cache: 'default'
            }
        },
        fallbackLng: 'pl',
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: '.'
        }
    }).then()

export default i18n
