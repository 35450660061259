import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const loadCategories = createAsyncThunk(
    'posts/loadPosts',
    async (option) => {

        let filter = '?'
        if (option.main_category) {
            filter += 'filter[main_category]=1&'
        }
        if (option.active) {
            filter += 'filter[active]=1&'
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/posts-categories${filter}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)
export const loadSliderPosts = createAsyncThunk(
    'posts/loadSliderPosts',
    async (option) => {
        const filter = '?filter[slider]=1'
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/posts${filter}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)

export const loadMainPosts = createAsyncThunk(
    'posts/loadMainPosts',
    async (option) => {
        const filter = '?filter[mainpost]=1'
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/posts${filter}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)
export const loadPost = createAsyncThunk(
    'posts/loadPost',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/posts/${option.id}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)
export const loadCategory = createAsyncThunk(
    'posts/loadCategory',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/posts-categories/${option.id}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)
export const posts = createSlice({
    name: 'posts',
    initialState: {
        posts: [],
        page: [],
        postsList: [],
        sliderPostsList: [],
        tilePostsList: [],
        post: {},
        categories: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadCategories.fulfilled, (state, action) => {
                if (action.payload) {
                    state.categories = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadPost.fulfilled, (state, action) => {
                if (action.payload) {
                    state.post = action.payload.data.data.attributes

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadSliderPosts.fulfilled, (state, action) => {
                if (action.payload) {
                    state.sliderPostsList = action.payload.data
                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadMainPosts.fulfilled, (state, action) => {
                if (action.payload) {
                    state.tilePostsList = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.posts = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
    }
})

export default posts.reducer