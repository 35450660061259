import React, {} from 'react'

const BlockTitle = (props) => {

    const {title} = props

    return (
        <div className="w-100 p-4 mb-3 text-center">
            <h1 className="fw-700 mb-0 mt-0 font-md text-grey-900 text-uppercase">
                {title}
            </h1>
        </div>
    )
}

export default BlockTitle