// ** Auth Endpoints
export default {
  loginEndpoint: "/api/front/login",
  loggedEndpoint: "/api/front/logged",
  registerEndpoint: "/api/front/register",
  refreshEndpoint: "/api/front/refresh",
  logoutEndpoint: "/api/front/logout",
  forgotPasswordEndpoint: "/api/front/forgot-password",
  resetPasswordEndpoint: "/api/front/reset-password",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer ",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
