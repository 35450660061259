import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
export const loadCategories = createAsyncThunk(
    'offers/loadCategories',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/offers-categories`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)

export const loadCategory = createAsyncThunk(
    'offers/loadCategory',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/offers-categories/${option.id}?userId=${option.userId}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)

export const loadOffer = createAsyncThunk(
    'offer/loadOffer',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/offers/${option.id}`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)

export const offers = createSlice({
    name: 'offers',
    initialState: {
        categories: [],
        category: [],
        offer: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadCategories.fulfilled, (state, action) => {
                if (action.payload) {
                    state.categories = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadCategory.fulfilled, (state, action) => {
                if (action.payload) {
                    state.category = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
            .addCase(loadOffer.fulfilled, (state, action) => {
                if (action.payload) {
                    state.offer = action.payload.data.data.attributes

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
    }
})

export default offers.reducer