import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

export const loadAds = createAsyncThunk(
    'ads/load',
    async (option) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/front/ads?filter[paid]=1`)
        if (response.status === 200) {
            return response
        }

        return false
    }
)


export const ads = createSlice({
    name: 'ads',
    initialState: {
        ads: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadAds.fulfilled, (state, action) => {
                if (action.payload) {
                    console.log(action.payload)
                    state.ads = action.payload.data.data
                }
            })
    }
})

export default ads.reducer