import { useState, createContext, useEffect } from "react"
import useJwt from '@src/auth/jwt/useJwt'
import { handleLogout, handleLogin } from '@store/authentication'
import { useDispatch } from 'react-redux'

const UserValue = createContext()

const UserContext = ({ children }) => {
    const dispatch = useDispatch()

    const [logged, setLogged] = useState(null)

    useEffect(() => {
        if (logged) {
            dispatch(handleLogin(logged))
            localStorage.setItem(useJwt.jwtConfig.storageTokenKeyName, JSON.stringify(logged.accessToken))
        } else {
            dispatch(handleLogout())
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        }
    }, [logged])

    return (
      <UserValue.Provider value={{
        logged, setLogged
      }}>{children}</UserValue.Provider>
    )
}

export { UserValue, UserContext }
