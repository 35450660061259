import React, {useEffect, useState, useContext} from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useJwt from '@src/auth/jwt/useJwt'
import {Link, useNavigate} from "react-router-dom"
import { AbilityContext } from '@src/utility/context/Can'
import { UserValue } from '@src/utility/context/User'
import InputPasswordToggle from "@components/input-password-toggle"

import { Alert, Spinner } from 'reactstrap'
import pusher from "../../../configs/pusher"
import {useTranslation} from "react-i18next"

const LoginForm = ({
    isLoginForm,
    setIsLoginForm
}) => {
    const {t} = useTranslation()
    const ability = useContext(AbilityContext)
    const {setLogged} = useContext(UserValue)
    const navigate = useNavigate()

    const [isLogin, setIsLogin] = useState(false)

    const [loginFormMessage, setLoginFormMessage]  = useState(null)

    const LoginFormSchema = yup.object().shape({
        email: yup.string().required(),
        password: yup.string().min(7).required()
    })
    const methods = useForm({ mode: 'onSubmit', resolver: yupResolver(LoginFormSchema) })
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = methods

    useEffect(() => {
        reset({})
        setLoginFormMessage(null)
    }, [isLoginForm])

    const onSubmit = (data) => {
        console.log('onSubmit')
        if (Object.values(data).length > 0) {
            setIsLogin(true)
            setLoginFormMessage(null)
            useJwt
                .login(data)
                .then(res => {
                    let errorText = 'Unkown error. Try later.'
                    let type = 'success'
                    if (res.data.success && res.data.success === true) {
                        reset({})
                        errorText = 'Success'
                        const data = {
                            ...res.data.user,
                            roles: res?.data?.user?.roles ?? [],
                            accessToken: res.data.token,
                            ability: res?.data?.ability ?? []
                        }
                        pusher.subscribe(`Chat.User.${res.data.user.id}`)
                        localStorage.setItem('userId', res.data.user.id)
                        setLogged(data)
                        ability.update(data.ability)
                        navigate('/')
                        useJwt.setToken(res.data.token)
                        useJwt.setRefreshToken(res.data.refreshToken)
                    } else {
                        type = 'danger'
                        if (res.data.error) {
                            errorText = '<ul>'
                            if (typeof res.data.error === 'string') {
                                errorText += `<li>${res.data.error}</li>`
                            } else {
                                Object.values(res.data.error).map((e, i) => {
                                    errorText += `<li>${e[0]}</li>`
                                })
                            }
                            errorText += '</ul>'
                        }
                        if (res.data.message) {
                            errorText = res.data.message
                        }
                    }
                    setLoginFormMessage({
                        type,
                        message: errorText
                    })
                    setIsLogin(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    return (
        <div className="mb-1 mt-2 d-flex flex-column px-3">
            {loginFormMessage &&
                <Alert className='font-xsss' color={loginFormMessage.type}>
                    <div dangerouslySetInnerHTML={{__html: loginFormMessage.message}}></div>
                </Alert>
            }
            <FormProvider {...methods}>
                <form className='sidebar-form mb-2' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        id='email'
                        name='email'
                        control={control}
                        render={({ field }) => (
                            <div className='mb-2'>
                                <div className="form-group icon-input">
                                    <i className="font-sm ti-user text-current pe-0"></i>
                                    <input {...field}
                                        autoComplete="off"
                                        type="email"
                                        className={`rounded-3 style5-input form-control text-grey-900${errors?.email ? ' is-invalid' : ''}`}
                                        placeholder={t('auth.placeholders.email')}
                                    />
                                </div>
                                <small className='text-danger font-xssss'>
                                    {errors?.email?.message}
                                </small>
                            </div>
                        )}
                    />

                    <Controller
                        id='password'
                        name='password'
                        control={control}
                        render={({ field }) => (
                            <div className='mb-2'>
                                <div className="form-group icon-input">
                                    <InputPasswordToggle
                                        withIcon={true}
                                        customProps={{...field}}
                                        inputClassName={`rounded-3 style5-input form-control text-grey-900${errors?.password_confirmation ? ' is-invalid' : ''}`}
                                        className={`input-group-merge`}
                                        placeholder={t('auth.placeholders.password')}
                                    />
                                </div>
                                <small className='text-danger font-xssss'>
                                    {errors?.password?.message}
                                </small>
                            </div>
                        )}
                    />

                    <div className="form-check text-left mb-1">
                        <input type="checkbox" className="form-check-input mt-2" id="exampleCheck5" />
                        <label className="form-check-label font-xssss text-grey-500">{t('auth.remember')}</label>
                    </div>
                    <a href="/forgot" className="fw-600 font-xssss text-grey-700 mb-3 d-block">{t('auth.forgot')}</a>

                    <div className="col-sm-12 p-0 text-left">
                        <div className="form-group mb-1">
                            <button disabled={isLogin} type="submit" className="rounded-3 bg-primary-gradiant sidebar-form-button form-control text-center style5-input text-white fw-600 border-0 p-0">
                                {isLogin &&
                                    <Spinner className='me-2' size={'sm'} />
                                }
                                {t('auth.login')}
                            </button>
                        </div>
                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">{t('auth.not_account')} <a href="#" className="fw-700 ms-1" onClick={(e) => { e.preventDefault(); setIsLoginForm(false) }}>{t('auth.register')}</a></h6>
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

export default LoginForm