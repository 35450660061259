import React, {useEffect, useState, useContext} from 'react'
import useJwt from '@src/auth/jwt/useJwt'
import {Link, useNavigate} from "react-router-dom"
import { UserValue } from '@src/utility/context/User'
import { AbilityContext } from '@src/utility/context/Can'

import {useTranslation} from "react-i18next"

const Account = () => {

    const navigate = useNavigate()
    const {t} = useTranslation()
    const {logged, setLogged} = useContext(UserValue)
    const ability = useContext(AbilityContext)

    return (
        <div className="mb-1 mt-2 d-flex flex-column px-3">
            <ul className="mb-1">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li><Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>{t('account.settings')}</span></Link></li>
                <li><Link to="/defaultanalytics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>Analytics</span></Link></li>
                <li><Link to="/chat" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>{t('account.chat')}</span><span className="circle-count bg-warning mt-0">23</span></Link></li>
                {logged &&
                    <li>
                        <Link to="/" className="nav-content-bttn open-font h-auto pt-2 pb-2" onClick={(e) => {
                            e.preventDefault()
                            useJwt.logout().then(res => {
                                setLogged(null)
                                ability.update([])
                                navigate("/")
                            })
                        }}>
                            <i className="font-sm feather-power me-3 text-grey-500"></i>
                            <span>{t('account.logout')}</span>
                        </Link>
                    </li>
                }
            </ul>
        </div>
    )
}

export default Account