// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import authentication from "./authentication"
import posts from './posts'
import offers from './offers'
import account from './account'
import ads from "./ads"

const rootReducer = { navbar, layout, authentication, posts, offers, account, ads }

export default rootReducer
