import {useParams} from "react-router-dom"
import {loadCategory} from "../redux/posts"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import BlockTitle from "../@core/components/BlockTitle"
import Postview from "../@core/components/Postview"
import i18n from "i18next"

const PostCategories = () => {
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(localStorage.getItem('perPage') ?? 10)
    const [sorting, setSorting] = useState({})

    const categoryId = params.categoryId
    const category = useSelector(state => state.posts.posts)

    const updateData = () => {
        setLoading(true)
        dispatch(loadCategory({
            id: categoryId
        }))
            .then(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        updateData()
        console.log(category)


    }, [currentPage, perPage, sorting])

    return (
        <div className="main-content">
            <div className="row mt-4 gap-y-2" key={category.id}>
                <BlockTitle title={category.categoryName}/>
                {
                    category.posts?.map(post => (
                        <div className="col-lg-4 col-md-6" key={`${category.id}-${post.id}`}>
                            <Postview id={post.id} postvideo={post.video} postimage={post.image}
                                      avater={post.userAvatar} title={JSON.parse(post.title)[i18n.language]} user={post.userName}
                                      time={post.time} des={JSON.parse(post.excerpt)[i18n.language]}/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default PostCategories