import React, {useEffect, useState} from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useJwt from '@src/auth/jwt/useJwt'
import InputPasswordToggle from "@components/input-password-toggle"
import { Alert, Spinner } from 'reactstrap'
import {useTranslation} from "react-i18next"

const RegisterForm = ({
    isLoginForm,
    setIsLoginForm
}) => {
    const {t} = useTranslation()
    const [isRegister, setIsRegister] = useState(false)
    const [regFormMessage, setRegFormMessage]  = useState(null)
    const [isRegistered, setIsRegistered] = useState(false)

    const RegFormSchema = yup.object().shape({
        username: yup.string().min(3).required(),
        email: yup.string().email().required(),
        password: yup.string().min(7).test(
                'passwordRequirements',
                'The password must include at least one uppercase and lowercase letter, one number and one special character',
                (value) => {
                    if (value) {
                        return [/[a-z]/, /[A-Z]/, /[0-9]/, /[a-zA-Z0-9]/, /[!@#\$%\^\&*\)\(+=._-]/].every((pattern) => (
                            pattern.test(value)
                        ))
                    }
                    return true
                }
            ).required(),
        password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords does not match').required(),
        accept: yup.bool().oneOf([true], 'Accept Term and Conditions').required()
    })
    const methods = useForm({ mode: 'onSubmit', resolver: yupResolver(RegFormSchema) })
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors }
    } = methods

    useEffect(() => {
        reset({})
        setRegFormMessage(null)
    }, [isLoginForm])

    const onSubmit = (data) => {
        if (Object.values(data).length > 0) {
            setIsRegister(true)
            useJwt
                .register(data)
                .then(res => {
                    let errorText = 'Unkown error. Try later.'
                    let type = 'success'
                    if (res.data.success && res.data.success === true) {
                        errorText = res.data.message
                        reset({})
                        setIsRegistered(true)
                    } else {
                        type = 'danger'
                        if (res.data.error) {
                            errorText = '<ul>'
                            Object.values(res.data.error).map((e, i) => {
                                errorText += `<li>${e[0]}</li>`
                            })
                            errorText += '</ul>'
                        }
                        if (res.data.message) {
                            errorText = res.data.message
                        }
                    }
                    setRegFormMessage({
                        type,
                        message: errorText
                    })
                    setIsRegister(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    return (
        <div className="mb-1 mt-2 d-flex flex-column px-3">
            {regFormMessage &&
                <Alert className='font-xsss' color={regFormMessage.type}>
                    <div dangerouslySetInnerHTML={{__html: regFormMessage.message}}></div>
                </Alert>
            }
            {!isRegistered &&
                <FormProvider {...methods}>
                    <form className='sidebar-form mb-2' onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            id='username'
                            name='username'
                            control={control}
                            render={({ field }) => (
                                <div className='mb-2'>
                                    <div className="form-group icon-input">
                                        <i className="font-sm ti-user text-current pe-0"></i>
                                        <input {...field}
                                            autoComplete="off"
                                            type="text" className={`rounded-3 style5-input form-control text-grey-900${errors?.username ? ' is-invalid' : ''}`} placeholder={t('auth.placeholders.username')} />
                                    </div>
                                    <small className='text-danger font-xssss'>
                                        {errors?.username?.message}
                                    </small>
                                </div>
                            )}
                        />

                        <Controller
                            id='email'
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <div className='mb-2'>
                                    <div className="form-group icon-input">
                                        <i className="font-sm ti-email text-current pe-0"></i>
                                        <input {...field}
                                            autoComplete="off"
                                            type="email"
                                            className={`rounded-3 style5-input form-control text-grey-900${errors?.email ? ' is-invalid' : ''}`}
                                            placeholder={t('auth.placeholders.email')}
                                        />
                                    </div>
                                    <small className='text-danger font-xssss'>
                                        {errors?.email?.message}
                                    </small>
                                </div>
                            )}
                        />

                        <Controller
                            id='password'
                            name='password'
                            control={control}
                            render={({ field }) => (
                                <div className='mb-2'>
                                    <div className="form-group icon-input">
                                        <InputPasswordToggle
                                            withIcon={true}
                                            customProps={{...field}}
                                            inputClassName={`rounded-3 style5-input form-control text-grey-900${errors?.password ? ' is-invalid' : ''}`}
                                            className={`input-group-merge`}
                                            placeholder={t('auth.placeholders.password')}
                                        />
                                    </div>
                                    <small className='text-danger font-xssss'>
                                        {errors?.password?.message}
                                    </small>
                                </div>
                            )}
                        />

                        <Controller
                            id='password_confirmation'
                            name='password_confirmation'
                            control={control}
                            render={({ field }) => (
                                <div className='mb-2'>
                                    <div className="form-group icon-input">
                                        <InputPasswordToggle
                                            withIcon={true}
                                            customProps={{...field}}
                                            inputClassName={`rounded-3 style5-input form-control text-grey-900${errors?.password_confirmation ? ' is-invalid' : ''}`}
                                            className={`input-group-merge`}
                                            placeholder={t('auth.placeholders.confirm_password')}
                                        />
                                    </div>
                                    <small className='text-danger font-xssss'>
                                        {errors?.password_confirmation?.message}
                                    </small>
                                </div>
                            )}
                        />

                        <Controller
                            id='accept'
                            name='accept'
                            control={control}
                            render={({ field }) => (
                                <div className='mb-3'>
                                    <div className="form-check text-left">
                                        <input {...field}
                                            autoComplete="off"
                                            type="checkbox"
                                            className={`rounded-3 form-check-input mt-2${errors?.accept ? ' is-invalid' : ''}`}
                                        />
                                        <label htmlFor='accept' className="form-check-label font-xssss text-grey-500">{t('auth.terms')}</label>
                                    </div>
                                    <small className='text-danger font-xssss'>
                                        {errors?.accept?.message}
                                    </small>
                                </div>
                            )}
                        />

                        <div className="col-sm-12 p-0 text-left">
                            <div className="form-group mb-1">
                                <button disabled={isRegister} type="submit" className="rounded-3 bg-primary-gradiant sidebar-form-button form-control text-center style5-input text-white fw-600 border-0 p-0">
                                    {isRegister &&
                                        <Spinner className='me-2' size={'sm'} />
                                    }
                                    {t('auth.register')}
                                </button>
                            </div>
                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">{t('auth.has_account')} <a href="#" className="fw-700 ms-1" onClick={(e) => { e.preventDefault(); setIsLoginForm(true) }}>{t('auth.login')}</a></h6>
                        </div>
                    </form>
                </FormProvider>
            }
        </div>
    )
}

export default RegisterForm