// ** Third Party Components

import ReactCountryFlag from "react-country-flag"

// ** Reactstrap Imports
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap"
import i18n from "i18next"

const IntlDropdown = () => {
    // ** Hooks

    // ** Vars
    const langObj = {
        pl: "Polski",
        ru: "Русский",
        ua: "Український"
    }

    // ** Function to switch Language
    const handleLangUpdate = (e, lang) => {
        e.preventDefault()
        i18n.changeLanguage(lang)
    }

    return (
        <UncontrolledDropdown
            href="/"
            tag="li"
            className="dropdown-language nav-item"
        >
            <DropdownToggle
                href="/"
                tag="a"
                className="nav-link"
                onClick={(e) => e.preventDefault()}
            >
                <ReactCountryFlag
                    svg
                    className="country-flag flag-icon"
                    countryCode={i18n.language}
                />
                {/* <span className="selected-language">{langObj[i18n.language]}</span> */}
            </DropdownToggle>
            <DropdownMenu className="mt-0" end>
                {Object.entries(langObj).map(([code, text]) => (
                        <DropdownItem
                            key={code}
                            href="/"
                            tag="a"
                            onClick={(e) => handleLangUpdate(e, code)}
                        >
                            <ReactCountryFlag className="country-flag" countryCode={code} svg/>
                            <span className="ms-1">{text}</span>
                        </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default IntlDropdown
