import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'

export const loadAccountInformation = createAsyncThunk(
    'account/me',
    async (option) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/me`)
        if (response.status === 200) {
            return response
        }
        return false
    }
)

export const updateAccountInformation = createAsyncThunk(
    'account/update',
    async (option) => {

    }
)

export const uploadAccountPhoto = createAsyncThunk(
    'account/photo',
    async (option) => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/media/upload`, {upload: option.photo})
    }
)

export const account = createSlice({
    name: 'account',
    initialState: {
        account: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadAccountInformation.fulfilled, (state, action) => {
                if (action.payload) {
                    state.account = action.payload.data

                    if (action.payload.data.meta) {
                        state.page = action.payload.data.meta.page
                    }
                }
            })
    }
})

export default account.reducer